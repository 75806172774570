import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c('BaseBreadcrumb'),_c('h1',{staticClass:"display-1"},[_vm._v("Sandbox")])],1),_c(VTabs,{attrs:{"grow":"","center-active":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(t,i){return _c(VTab,{key:i,staticClass:"text-capitalize",on:{"click":function($event){return _vm.changeTabURL(i)}}},[_vm._v(" "+_vm._s(t)+" ")])}),1),_c(VContainer,{attrs:{"fluid":""}},[_c(VTabsItems,{staticClass:"mt-3",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('tax-cpi-rate-growth')],1),_c(VTabItem,[_c('default-colors')],1),_c(VTabItem,[_c('ConfigFile')],1),_c(VTabItem,[_c('ReviewTeamAssignment')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }