import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"card-title--success"},[_c(VCardTitle,[_vm._v(" Tax CPI Rate Growth "),_c(VIcon,{staticClass:"ml-2",attrs:{"color":"white"}},[_vm._v("mdi-chart-line")])],1),_c(VCardText,{staticClass:"mt-5"},[(_vm.loading.table)?_c('div',[_c(VSkeletonLoader,{attrs:{"type":"table"}}),_c('div',{staticClass:"d-flex justify-center"},[_c(VSkeletonLoader,{attrs:{"type":"button"}})],1)],1):_c('div',[_c('div',[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.taxCpiGrowthData.data},scopedSlots:_vm._u([{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.organization)+" ")]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-space-around flex-wrap"},[_c('div',{staticClass:"mb-2"},[_vm._v(" Updated "),(_vm.taxCpiGrowthData.timestampUpdated)?_c('date-hover',{attrs:{"smallFont":false,"bottom":"","date":_vm.taxCpiGrowthData.timestampUpdated}}):_vm._e()],1),_c('div',{staticClass:"mb-2"},[_c(VBtn,{attrs:{"outlined":"","large":"","color":"success"},on:{"click":function($event){return _vm.downloadCSV()}}},[_vm._v("Download CSV "),_c(VIcon,[_vm._v("mdi-file-download")])],1)],1),_c('div',{staticClass:"mb-2"},[_c(VBtn,{attrs:{"loading":_vm.loading.generateData,"large":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.generateData()}}},[_vm._v("Reload "),_c(VIcon,[_vm._v("mdi-reload")])],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }