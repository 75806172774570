<template>
  <v-card class="card-title--success">
    <v-card-title>
      Tax CPI Rate Growth
      <v-icon class="ml-2" color="white">mdi-chart-line</v-icon></v-card-title
    >
    <v-card-text class="mt-5">
      <div v-if="loading.table">
        <v-skeleton-loader type="table"></v-skeleton-loader>
        <div class="d-flex justify-center">
          <v-skeleton-loader type="button"></v-skeleton-loader>
        </div>
      </div>
      <div v-else>
        <div>
          <v-data-table :headers="headers" :items="taxCpiGrowthData.data">
            <template v-slot:[`item.organization`]="{ item }">
              {{ item.organization }}
            </template>
          </v-data-table>
        </div>
        <div class="d-flex justify-space-around flex-wrap">
          <div class="mb-2">
            Updated
            <date-hover
              :smallFont="false"
              bottom
              v-if="taxCpiGrowthData.timestampUpdated"
              :date="taxCpiGrowthData.timestampUpdated"
            ></date-hover>
          </div>
          <div class="mb-2">
            <v-btn outlined large color="success" @click="downloadCSV()"
              >Download CSV <v-icon>mdi-file-download</v-icon></v-btn
            >
          </div>
          <div class="mb-2">
            <v-btn
              :loading="loading.generateData"
              large
              color="primary"
              outlined
              @click="generateData()"
              >Reload <v-icon>mdi-reload</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { taxRef, adminDataRef, parcelRef, userRef } from '@/firebase/db';
import { mapGetters, mapMutations } from 'vuex';
import { Parser } from 'json2csv';
import tax from '@/mixins/tax';
import parcel from '@/mixins/parcel';
import Parcel from '../../services/parcel';
import Organization from '../../services/organization';
export default {
  mixins: [tax, parcel],
  components: {
    DateHover: () => import('../../components/Core/DateHover.vue')
  },
  data() {
    return {
      DOCUMENT_NAME: 'taxCpiRateGrowth',
      MIN_TAX_ITEMS: 5,
      headers: [
        { text: 'First Name', value: 'user.firstName', align: '' },
        { text: 'Last Name', value: 'user.lastName', align: '' },
        { text: 'Email', value: 'user.email', align: '' },
        { text: 'Organization', value: 'organization', align: '' },
        { text: 'Street', value: 'parcel.streetAddress' },
        { text: 'map-parcel', value: 'parcel.number' },
        { text: 'Parcel Type', value: 'parcel.type' },
        { text: 'First Year', value: 'firstYear' },
        { text: 'Latest Year', value: 'lastYear' },
        { text: 'Tax Years', value: 'taxYearCount', align: '' },
        { text: 'Latest Tax $', value: 'lastTax' },
        { text: 'Tax Increase', value: 'taxGrowth' },
        { text: 'CPI Increase', value: 'cpiGrowth' },
        { text: 'Growth Rate', value: 'growthRate', align: '' },
        { text: 'RTE Start', value: 'rteStart', align: '' },
        { text: 'RTE', value: 'rteYears', align: '' },
        { text: '2025 Increase', value: 'estimated.2025', align: '' },
        { text: '2030 Increase', value: 'estimated.2030', align: '' }
      ],
      doesNotExist: false,
      loading: {
        table: true,
        generateData: false
      }
    };
  },
  computed: {
    ...mapGetters({
      taxCpiGrowth: 'admin/taxCpiGrowth',
      taxCpiGrowthData: 'admin/taxCpiGrowthData'
    })
  },
  async beforeMount() {
    this.getData();
  },
  methods: {
    ...mapMutations({
      setTaxCpiGrowthByKey: 'admin/setTaxCpiGrowthByKey'
    }),
    setData(data) {
      this.setTaxCpiGrowthByKey({
        key: 'data',
        value: data
      });
    },
    async getData() {
      let data;
      try {
        data = await adminDataRef.doc(this.DOCUMENT_NAME).get();
      } catch (err) {
        console.log(err);
      }
      this.loading.table = false;

      if (data.exists) {
        this.setData(data.data());
      } else {
        this.doesNotExist = true;
      }
    },
    async saveTaxCpiGrowth(data) {
      data = { data: data, timestampUpdated: new Date() };
      await adminDataRef.doc(this.DOCUMENT_NAME).set(data);
      this.setData(data);
    },
    async processData(data) {
      // check if tax history exists and valid
      if (!data.data || typeof data.data !== 'object') {
        return;
      }
      let dataSize = data.data.length - 1;
      if (data.data[0].year > data.data[dataSize].year) {
        data.data = data.data.reverse();
      }

      let parcel = await parcelRef.doc(data.id).get();
      if (!parcel.exists) {
        return;
      }
      parcel = parcel.data();

      let res = {
        taxYearCount: null,
        growthRate: null,
        rteYears: 0,
        user: {},
        parcel: {},
        estimated: {}
      };

      data.data.map(e => {
        if (e.residentialTaxExemption) {
          if (!res.rteStart) {
            res.rteStart = e.year;
          }
          res.rteYears++;
        }
      });

      if (!res.rteYears) {
        res.rteYears = '';
      }

      let taxSummary = this.getTaxSummary(data.data);
      if (!taxSummary) {
        return;
      }

      let user = await userRef.doc(parcel.user).get();
      const org = new Organization();
      const orgQuery = await org.retrieve(parcel.user);

      let orgData;
      if (user.exists || orgQuery.exists) {
        orgData = orgQuery.data();
        user = await userRef.doc(orgData.user).get();
      } else {
        return;
      }
      const isOrg = orgQuery.exists;

      // set user details
      user = user.data();
      res.user.email = user.email;
      res.user.firstName = user.first_name;
      res.user.lastName = user.last_name;
      res.organization = orgData?.name;
      res.organizationId = orgQuery?.id;

      // set parcel details
      const p = new Parcel();
      res.parcel.streetAddress = this.getStreetAddress(parcel);
      res.parcel.number = parcel.number;
      res.parcel.type = p.type(parcel.parcel_type);

      // set tax history summary
      res.taxYearCount = data.data.length;
      res.growthRate = taxSummary.growthRate;

      res.firstYear = taxSummary.firstYear;
      res.lastYear = taxSummary.lastYear;

      res.lastTax = taxSummary.lastTax;
      res.taxGrowth = taxSummary.taxGrowth;
      res.cpiGrowth = taxSummary.cpiGrowth;

      res.estimated[2025] = taxSummary.estimated[2025];
      res.estimated[2030] = taxSummary.estimated[2030];

      return res;
    },
    async generate() {
      let query = await taxRef.get();
      let data = [];
      query.forEach(tax => {
        data.push({ ...tax.data(), id: tax.id });
      });

      data = data.filter(e => {
        return e && e.data.length > this.MIN_TAX_ITEMS;
      });

      data = data.map(e => {
        return this.processData(e);
      });

      data = await Promise.all(data);
      console.log(data);
      data = data.filter(e => e);

      this.saveTaxCpiGrowth(data);
    },
    async generateData() {
      this.loading.generateData = true;
      try {
        await this.generate();
      } catch (err) {
        console.log(err);
      }
      this.loading.generateData = false;
    },
    downloadCSV() {
      let headers = this.copyObject(this.headers);
      headers = headers.map(e => {
        e.label = e.text;
        return e;
      });

      try {
        const opts = { fields: headers };
        const parser = new Parser(opts);
        const csv = parser.parse(this.taxCpiGrowthData.data);
        this.downloadFile(`data:text/csv;charset=utf-8,${encodeURI(csv)}`, 'Tax  CPI Growth.csv');
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<style scoped></style>
