import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-2"},[_c('div',{staticClass:"my-2"},[_c(VChip,{staticClass:"text-capitalize",attrs:{"color":_vm.colors.deployment[_vm.config.deployment]}},[_vm._v(" "+_vm._s(_vm.config.deployment)+" ")])],1),_c('div',[_c(VCard,{staticClass:"card-title--primary",attrs:{"hover":""}},[_c(VCardTitle,[_vm._v(" Data ")]),_c(VCardText,[_c('json-viewer',{attrs:{"value":_vm.config,"expand-depth":5,"copyable":""}})],1)],1)],1),_c(VRow,{staticClass:"mt-2"},_vm._l((_vm.functions),function(fn){return _c(VCol,{key:fn,attrs:{"cols":"12","sm":"6"}},[_c(VCard,{staticClass:"card-title--primary",attrs:{"hover":""}},[_c(VCardTitle,[_c('code',[_vm._v(" "+_vm._s((fn + "()"))+" ")])]),_c(VCardText,[_c('json-viewer',{attrs:{"value":_vm.config[fn](),"expand-depth":5,"copyable":""}})],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }