<template>
  <v-main>
    <v-container fluid>
      <BaseBreadcrumb />
      <h1 class="display-1">Sandbox</h1>
    </v-container>
    <v-tabs v-model="tab" grow center-active>
      <v-tab @click="changeTabURL(i)" v-for="(t, i) in tabs" :key="i" class="text-capitalize">
        {{ t }}
      </v-tab>
    </v-tabs>
    <v-container fluid>
      <v-tabs-items v-model="tab" class="mt-3">
        <v-tab-item>
          <tax-cpi-rate-growth></tax-cpi-rate-growth>
        </v-tab-item>
        <v-tab-item>
          <default-colors></default-colors>
        </v-tab-item>
        <v-tab-item>
          <ConfigFile />
        </v-tab-item>
        <v-tab-item>
          <ReviewTeamAssignment />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-main>
</template>

<script>
import TaxCpiRateGrowth from '@/components/Admin/TaxCpiRateGrowth';
import DefaultColors from '../../../components/Admin/DefaultColors';
import ConfigFile from '../../../components/Admin/ConfigFile';
export default {
  components: {
    TaxCpiRateGrowth,
    DefaultColors,
    ConfigFile,
    BaseBreadcrumb: () => import('../../../components/Core/Base/BreadCrumb.vue'),
    ReviewTeamAssignment: () => import('../../../components/Admin/ReviewTeamAssignment.vue')
  },
  data() {
    return {
      tab: null,
      tabs: ['Tax CPI Rate Growth', 'Colors', 'Config File', 'Review Team Assignment']
    };
  },
  mounted() {
    this.setTabFromURL();
  },
  methods: {
    setTabFromURL() {
      let tab = this.$route.query.tab;
      tab = Number(tab);
      if (tab) {
        this.changeTabURL(tab);
        this.tab = tab;
      }
    },
    changeTabURL(tab) {
      let url = `${this.$route.path}?tab=${tab}`;
      if (this.$route.fullPath != url) {
        this.$router.push(url);
      }
    }
  }
};
</script>

<style></style>
